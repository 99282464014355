:root {
  --tbc-color-white: #fff;
  --tbc-color-gray-100: #f8f9fa;
  --tbc-color-gray-200: #f7f7f9;
  --tbc-color-gray-300: #eceeef;
  --tbc-color-gray-400: #ced4da;
  --tbc-color-gray-500: #adb5bd;
  --tbc-color-gray-600: #919aa1;
  --tbc-color-gray-700: #55595c;
  --tbc-color-gray-800: #343a40;
  --tbc-color-gray-900: #1a1a1a;
  --tbc-color-black: #000;
  --tbc-color-blue: #007bff;
  --tbc-color-indigo: #6610f2;
  --tbc-color-purple: #6f42c1;
  --tbc-color-pink: #e83e8c;
  --tbc-color-red: #d9534f;
  --tbc-color-orange: #fd7e14;
  --tbc-color-yellow: #f0ad4e;
  --tbc-color-green: #4bbf73;
  --tbc-color-teal: #20c997;
  --tbc-color-cyan: #1f9bcf;

  --tbc-color-primary: #1a1a1a;
  --tbc-color-secondary: #ffffff;
  --tbc-color-success: #4bbf73;
  --tbc-color-info: #1f9bcf;
  --tbc-color-warning: #f0ad4e;
  --tbc-color-danger: #d9534fd;
  --tbc-color-light: var(--tbc-color-white);
  --tbc-color-dark: var(--tbc-color-gray-800);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-outline-primary {
  color: var(--tbc-color-black);
  border-color: var(--tbc-color-black);
}

.btn-outline-primary:active {
  color: var(--tbc-color-black);
  border-color: var(--tbc-color-black);
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--tbc-color-gray-600);
  border-color: var(--tbc-color-gray-600);
}

.btn-check:checked+.btn-outline-primary {
  color: #fff;
  background-color: var(--tbc-color-black);
  border-color: var(--tbc-color-black);
}

.btn-check:checked+.btn-outline-primary:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  font-weight: 400;
  background-color: var(--tbc-color-gray-600);
  box-shadow: none;
}