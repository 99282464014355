.btn-primary {
  font-size: .875rem;
  text-transform: uppercase;
  color: var(--tbc-color-white);
  background-color: var(--tbc-color-gray-900);
  border-color: var(--tbc-color-gray-900);
}

.btn-primary:hover {
  color: var(--tbc-color-white);
  background-color: var(--tbc-color-black);
  border-color: var(--tbc-color-black);
}