.link {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--tbc-color-gray-900);
  text-decoration: none;
}

.link:hover {
  color: var(--tbc-color-gray-900);
  text-decoration: underline;
}