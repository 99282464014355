.header {
  padding: 0 0.5rem;
  border-bottom: 3px solid var(--tbc-color-gray-200);
  margin-bottom: 1rem;
}

.title {
  font-size: 20px;
  margin: 0;
  padding: 6px 0;
  line-height: 1;
  font-weight: 700;
}

.title a {
  color: #000;
  text-decoration: none;
}