.footer {
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  padding: 50px 25px 30px 25px;
  background-color: var(--tbc-color-gray-800);
  color: var(--tbc-color-gray-300);
}

.legal {
  font-weight: 200;
}


.footer a {
  color: var(--tbc-color-gray-200);
}